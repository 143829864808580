// Type
// ====
.slds-text-heading_medium {
  font-family: var(--wes-g-font-family-display);
  font-weight: var(--wes-s-heading-font-weight);
  font-size: var(--wes-g-font-size-4);
  line-height: 1.75rem;
  letter-spacing: -0.08px;
  color: var(--wes-g-color-palette-blue-20);
}

.text-transform_uppercase {
  text-transform: uppercase;
}
