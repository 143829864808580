.slds-tabs_default__nav {
  font-size: 1rem;
  font-weight: 600;
  padding: 0 var(--wes-g-spacing-10);
  flex-wrap: wrap;

  & .slds-tabs_default__item {
    padding: var(--wes-g-spacing-2) var(--wes-g-spacing-5)
      var(--wes-g-spacing-1) var(--wes-g-spacing-5);

    &.slds-is-active {
      color: var(--wes-g-color-palette-blue-50);
      font-weight: 600;
    }

    &:not(:last-of-type) {
      margin-right: var(--wes-g-spacing-5);
    }
  }
}
