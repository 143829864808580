.dc-header {
  align-items: center;
  position: absolute;
  height: 96px;
  padding: 24px 40px;
  width: 100%;
  z-index: 1;
  background-color: var(--wes-g-color-neutral-base-1);
  font-family: var(--wes-g-font-family-sans);
  border: none;
  box-shadow: none;

  nav {
    display: inline-flex;
    align-items: center;
    margin-left: 40px;

    a {
      font-weight: 700;
      font-size: 16px;
      line-height: 28px;
      color: var(--wes-g-color-brand-inverse-2);
      text-decoration: none;
      margin-right: 32px;

      &:hover,
      &:focus {
        color: var(--wes-g-color-brand-base-contrast-2);
      }
    }
  }

  & + .slds-page-header.page-header {
    margin-top: 100px;
  }
}
