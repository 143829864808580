.metadeploy-frame {
  background: linear-gradient(
      180deg,
      rgb(234 245 254 / 0%) 0%,
      var(--wes-g-color-palette-cloud-blue-95) 100%
    ),
    var(--wes-g-color-brand-base-1);
}

.content-main {
  position: relative;
}

.section-intro {
  padding: 60px 0;
}
