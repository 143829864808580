.site-contentinfo {
  color: $color-gray-1;
  padding: 65px 80px 50px;
  background-color: $palette-blue-20;

  .logo-col {
    width: 70px;
    padding-right: 20px;
  }

  a {
    text-decoration: underline;
    color: $color-text-link-inverse;

    &:hover,
    &:focus {
      color: $color-text-link-inverse-hover;
    }
  }
}

.footer-display-image {
  background-color: #eaf5fe;
  background-size: 100% 100%;
  background-position: bottom;
  display: block;
  width: 100%;
  background-repeat: no-repeat;
  height: 90px;

  & svg {
    width: 100%;
    height: 100%;
  }
}
