a.industry-card {
  color: var(--wes-g-color-brand-inverse-2);

  &:hover,
  &:focus {
    color: var(--wes-g-color-brand-base-contrast-2);
  }
}

.industry-card {
  & > div {
    align-items: center;
    width: 160px;
  }

  wes-card {
    &::part(card) {
      border-radius: var(--wes-g-radius-border-circle);
    }

    &::part(header),
    &::part(footer) {
      display: none;
    }

    &::part(body) {
      padding: 1.5rem;
      margin: 0;
    }

    wes-image {
      &::part(image) {
        object-fit: contain;
      }
    }
  }

  .industry-title {
    font-size: var(--wes-g-font-size-3);
    font-weight: 700;
  }
}

.solution-card {
  width: 270px;
  height: 350px;
  display: flex;

  wes-card {
    display: flex;
    max-width: 100%;

    &::part(card) {
      padding: 0;
    }

    &::part(header) {
      padding: var(--wes-g-spacing-4) var(--wes-g-spacing-6);
    }

    &::part(body) {
      margin: 0;
      padding: var(--wes-g-spacing-4) var(--wes-g-spacing-6)
        var(--wes-g-spacing-6) var(--wes-g-spacing-6);
    }

    &::part(footer) {
      display: none;
    }

    & .image-container {
      text-align: center;
      padding: var(--wes-g-spacing-8);
      background-color: var(--wes-g-color-brand-base-2);
    }

    & .badge-container {
      padding: 0 var(--wes-g-spacing-6);
    }

    wes-badge {
      margin-left: -10px;
    }
  }
}

.install-card {
  & ::part(card) {
    padding: var(--wes-g-spacing-2) var(--wes-g-spacing-6);
  }

  & ::part(header) {
    padding-bottom: 0;
  }

  & ::part(body) {
    padding-top: var(--wes-g-spacing-2);
  }

  & ::part(footer) {
    display: none;
  }
}
