.landing-banner {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right top;
  height: 600px;
  margin-top: 96px;

  .slds-combobox {
    text-align: left;

    .slds-input.slds-combobox__input {
      padding: 5px 25px 5px 40px;
      border-radius: 25px;
      border: 1px solid #747474;
    }

    .slds-input-has-icon_right {
      .slds-input__icon {
        width: 1rem;
        height: 1rem;
        right: auto;
        left: 1rem;
        margin-top: -0.42rem;
        fill: var(--wes-g-color-palette-blue-20);
      }
    }

    #combobox-landing-search-listbox {
      width: 80%;
    }
  }
}

.banner-foreground {
  background-color: rgb(255 255 255 / 30%);
  width: 100%;
  height: 100%;
}

.product-banner-container {
  width: 100%;
  height: 500px;
  margin-top: 96px;

  .banner {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    padding: var(--wes-g-spacing-6) var(--wes-g-spacing-10);

    & .back-link {
      text-decoration: none;

      --sds-c-icon-sizing-width: 12px;
      --sds-c-icon-sizing-height: 12px;
    }
  }
}
